<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户名/手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户状态" prop="status">
                <a-select v-model="queryParam.status" show-search placeholder="请选择">
                  <a-select-option v-for="(item,key) in [{name:'禁用',id:0},{name:'正常',id:1}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <!-- <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['demo:student:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['demo:student:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['demo:student:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['demo:student:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div> -->
      <device
        ref="device"
        @ok="getList"
      />
      <log
        ref="log"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="avatar" slot-scope="text, record">
          <img :src="record.avatar" style="width: 30px;height: 30px;" v-if="record.avatar">
        </span>

        <span slot="status" slot-scope="text, record">
          <a-popconfirm
            ok-text="是"
            cancel-text="否"
            @confirm="confirmHandleStatus(record)"
            @cancel="cancelHandleStatus(record)"
            v-hasPermi="['user:user:qy']">
            <span slot="title">确认<b>{{ record.status == 0 ? '启用' : '停用' }}</b>{{ record.nickName }}的用户吗?</span>
            <a-switch checked-children="开" un-checked-children="关" :checked="record.status === 1 " />
          </a-popconfirm>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.device.info(record.id)" v-hasPermi="['user:user:dev:list']"><a-icon type="eye"/>设备</a>
          <a-divider type="vertical" v-hasPermi="['wisdom:devicelog:user:list']"/>
          <a @click="$refs.log.info(record.id)" v-hasPermi="['wisdom:devicelog:user:list']">
            <a-icon type="eye" />设备日志
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageStudent, userStatus } from '@/api/demo/student'
import device from './modules/device'
import log from './modules/log'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Student',
  components: {
    device, log
  },
  mixins: [tableMixin],
  data () {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      list: [],
      // 查询参数
      queryParam: {
        nickname: null,
        mobile: null,
        email: null,
        unitName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户名/手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 160,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询学生信息列表 */
    getList () {
      this.loading = true
     pageStudent(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        nickname: null,
        mobile: null,
        email: null,
        unitName: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    /* 用户状态修改 status 0开启 1关闭 */
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === 0 ? '启用' : '关闭'
      const status = row.status === 1 ? 0 : 1
      userStatus(row.id, status).then((res) => {
        if (res.success) {
          row.status = status
          this.$message.success(text + '成功', 3)
        } else {
          row.status = oldStatus
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
        this.getList()
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    },
    cancelHandleStatus(row) {
    }
  }
}
</script>
